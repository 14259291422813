<template>
  <div>
    <b-card>
      <b-table
        :items="bankAccounts"
        :fields="fields"
        :busy="isLoading"
        responsive
        show-empty
      >
        <template #cell(bankCode)="data">
          <bank-icon :bank-code="data.value" />
          {{ $displayBankName(data.value) }}
        </template>
        <template #cell(minTotalDeposit)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(isEnable)="data">
          <b-badge :variant="data.value ? 'success' : 'danger'">
            {{
              data.value ? $t(`utils.status.active`) : $t(`utils.status.inactive`)
            }}
          </b-badge>
        </template>
        <template #cell(isAutoEnable)="data">
          <b-badge :variant="data.value ? 'success' : 'danger'">
            {{
              data.value ? $t(`utils.status.active`) : $t(`utils.status.inactive`)
            }}
          </b-badge>
        </template>
        <template #cell(bankUseType)="data">
          <b-badge
            v-if="data.value === 0"
            variant="primary"
          >
            {{
              $t('bank_accounts.types.deposit_withdraw')
            }}
          </b-badge>
          <b-badge
            v-if="data.value === 1"
            variant="success"
          >
            {{
              $t('bank_accounts.types.deposit')
            }}
          </b-badge>
          <b-badge
            v-if="data.value === 2"
            variant="danger"
          >
            {{
              $t('bank_accounts.types.withdraw')
            }}
          </b-badge>
        </template>
        <template #cell(id)="data">
          <b-dropdown
            v-if="$allowPermission('agent:manage.bank-account')"
            variant="info"
            size="sm"
            dropleft
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="SettingsIcon"
                size="20"
              />
            </template>
            <b-dropdown-item @click="onSelect(data.item)">
              <feather-icon
                icon="EditIcon"
                class="mr-1"
              />
              {{ $t('buttons.edit') }}
            </b-dropdown-item>
            <b-dropdown-item
              variant="danger"
              @click="onDelete(data.value)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-1"
              />
              <span>
                {{ $t('buttons.remove') }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            {{ $t('messages.nothing_here') }}
          </p>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    bankAccounts: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'bankAccountName',
          label: `${this.$t('bank_accounts.name')}`,
          thStyle: {
            minWidth: '180px',
          },
        },
        {
          key: 'bankCode',
          label: `${this.$t('bank_accounts.bank')}`,
          thStyle: {
            minWidth: '280px',
          },
        },
        {
          key: 'bankAccountNumber',
          label: `${this.$t('bank_accounts.account_no')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'agent.name',
          label: `${this.$t('bank_accounts.agent_username')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'bankAccountName',
          label: `${this.$t('bank_accounts.account_name')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'bankUseType',
          label: `${this.$t('bank_accounts.type')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'minTotalDeposit',
          label: 'สำหรับยอดฝาก',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'isEnable',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
        },
        {
          key: 'isAutoEnable',
          label: 'ระบบออโต้',
          class: 'text-center',
        },
        {
          key: 'id',
          label: '',
          width: '100',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {},
  methods: {
    onSelect(value) {
      this.$emit('select', value)
    },
    onDelete(value) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.$emit('delete', value)
          }
        })
    },
  },
}
</script>
